/* -------------------------------------------------------------------------- */
/*                                   leaflet                                  */
/* -------------------------------------------------------------------------- */

const leafletActiveUserInit = () => {
  const points = [
    {
      lat: 53.958332,
      long: -1.080278,
      name: 'Diana Meyer',
      street: 'Slude Strand 27',
      location: '1130 Kobenhavn',
    },
    {
      lat: 52.958332,
      long: -1.080278,
      name: 'Diana Meyer',
      street: 'Slude Strand 27',
      location: '1130 Kobenhavn',
    },
    {
      lat: 51.958332,
      long: -1.080278,
      name: 'Diana Meyer',
      street: 'Slude Strand 27',
      location: '1130 Kobenhavn',
    },
    {
      lat: 53.958332,
      long: -1.080278,
      name: 'Diana Meyer',
      street: 'Slude Strand 27',
      location: '1130 Kobenhavn',
    },
    {
      lat: 54.958332,
      long: -1.080278,
      name: 'Diana Meyer',
      street: 'Slude Strand 27',
      location: '1130 Kobenhavn',
    },
    {
      lat: 55.958332,
      long: -1.080278,
      name: 'Diana Meyer',
      street: 'Slude Strand 27',
      location: '1130 Kobenhavn',
    },
    {
      lat: 53.908332,
      long: -1.080278,
      name: 'Diana Meyer',
      street: 'Slude Strand 27',
      location: '1130 Kobenhavn',
    },
    {
      lat: 53.008332,
      long: -1.080278,
      name: 'Diana Meyer',
      street: 'Slude Strand 27',
      location: '1130 Kobenhavn',
    },
    {
      lat: 53.158332,
      long: -1.080278,
      name: 'Diana Meyer',
      street: 'Slude Strand 27',
      location: '1130 Kobenhavn',
    },
    {
      lat: 53.000032,
      long: -1.080278,
      name: 'Diana Meyer',
      street: 'Slude Strand 27',
      location: '1130 Kobenhavn',
    },
    {
      lat: 52.292001,
      long: -2.22,
      name: 'Anke Schroder',
      street: 'Industrivej 54',
      location: '4140 Borup',
    },
    {
      lat: 52.392001,
      long: -2.22,
      name: 'Anke Schroder',
      street: 'Industrivej 54',
      location: '4140 Borup',
    },
    {
      lat: 51.492001,
      long: -2.22,
      name: 'Anke Schroder',
      street: 'Industrivej 54',
      location: '4140 Borup',
    },
    {
      lat: 51.192001,
      long: -2.22,
      name: 'Anke Schroder',
      street: 'Industrivej 54',
      location: '4140 Borup',
    },
    {
      lat: 52.292001,
      long: -2.22,
      name: 'Anke Schroder',
      street: 'Industrivej 54',
      location: '4140 Borup',
    },
    {
      lat: 54.392001,
      long: -2.22,
      name: 'Anke Schroder',
      street: 'Industrivej 54',
      location: '4140 Borup',
    },
    {
      lat: 51.292001,
      long: -2.22,
      name: 'Anke Schroder',
      street: 'Industrivej 54',
      location: '4140 Borup',
    },
    {
      lat: 52.102001,
      long: -2.22,
      name: 'Anke Schroder',
      street: 'Industrivej 54',
      location: '4140 Borup',
    },
    {
      lat: 52.202001,
      long: -2.22,
      name: 'Anke Schroder',
      street: 'Industrivej 54',
      location: '4140 Borup',
    },
    {
      lat: 51.063202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.363202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.463202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.563202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.763202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.863202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.963202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.000202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.000202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.163202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 52.263202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 53.463202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 55.163202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 56.263202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 56.463202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 56.563202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 56.663202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 56.763202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 56.863202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 56.963202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 57.973202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 57.163202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.163202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.263202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.363202,
      long: -1.308,
      name: 'Tobias Vogel',
      street: 'Mollebakken 33',
      location: '3650 Olstykke',
    },
    {
      lat: 51.409,
      long: -2.647,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.68,
      long: -1.49,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 50.259998,
      long: -5.051,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 54.906101,
      long: -1.38113,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.383331,
      long: -1.466667,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.483002,
      long: -2.2931,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 51.509865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 51.109865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 51.209865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 51.309865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 51.409865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 51.609865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 51.709865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 51.809865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 51.909865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.109865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.209865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.309865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.409865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.509865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.609865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.709865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.809865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.909865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.519865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.529865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.539865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.549865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 52.549865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.109865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.209865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.319865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.329865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.409865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.559865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.619865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.629865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.639865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.649865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.669865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.669865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.719865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.739865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.749865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.759865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.769865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.769865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.819865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.829865,
      long: -0.118092,
      name: 'Richard Hendricks',
      street: '37 Seafield Place',
      location: 'London',
    },
    {
      lat: 53.483959,
      long: -2.244644,
      name: 'Ethel B. Brooks',
      street: '2576 Sun Valley Road',
    },
    {
      lat: 40.737,
      long: -73.923,
      name: 'Marshall D. Lewis',
      street: '1489 Michigan Avenue',
      location: 'Michigan',
    },
    {
      lat: 39.737,
      long: -73.923,
      name: 'Marshall D. Lewis',
      street: '1489 Michigan Avenue',
      location: 'Michigan',
    },
    {
      lat: 38.737,
      long: -73.923,
      name: 'Marshall D. Lewis',
      street: '1489 Michigan Avenue',
      location: 'Michigan',
    },
    {
      lat: 37.737,
      long: -73.923,
      name: 'Marshall D. Lewis',
      street: '1489 Michigan Avenue',
      location: 'Michigan',
    },
    {
      lat: 40.737,
      long: -73.923,
      name: 'Marshall D. Lewis',
      street: '1489 Michigan Avenue',
      location: 'Michigan',
    },
    {
      lat: 41.737,
      long: -73.923,
      name: 'Marshall D. Lewis',
      street: '1489 Michigan Avenue',
      location: 'Michigan',
    },
    {
      lat: 42.737,
      long: -73.923,
      name: 'Marshall D. Lewis',
      street: '1489 Michigan Avenue',
      location: 'Michigan',
    },
    {
      lat: 43.737,
      long: -73.923,
      name: 'Marshall D. Lewis',
      street: '1489 Michigan Avenue',
      location: 'Michigan',
    },
    {
      lat: 44.737,
      long: -73.923,
      name: 'Marshall D. Lewis',
      street: '1489 Michigan Avenue',
      location: 'Michigan',
    },
    {
      lat: 45.737,
      long: -73.923,
      name: 'Marshall D. Lewis',
      street: '1489 Michigan Avenue',
      location: 'Michigan',
    },
    {
      lat: 46.7128,
      long: 74.006,
      name: 'Elizabeth C. Lyons',
      street: '4553 Kenwood Place',
      location: 'Fort Lauderdale',
    },
    {
      lat: 40.7128,
      long: 74.1181,
      name: 'Elizabeth C. Lyons',
      street: '4553 Kenwood Place',
      location: 'Fort Lauderdale',
    },
    {
      lat: 14.235,
      long: 51.9253,
      name: 'Ralph D. Wylie',
      street: '3186 Levy Court',
      location: 'North Reading',
    },
    {
      lat: 15.235,
      long: 51.9253,
      name: 'Ralph D. Wylie',
      street: '3186 Levy Court',
      location: 'North Reading',
    },
    {
      lat: 16.235,
      long: 51.9253,
      name: 'Ralph D. Wylie',
      street: '3186 Levy Court',
      location: 'North Reading',
    },
    {
      lat: 14.235,
      long: 51.9253,
      name: 'Ralph D. Wylie',
      street: '3186 Levy Court',
      location: 'North Reading',
    },
    {
      lat: 15.8267,
      long: 47.9218,
      name: 'Hope A. Atkins',
      street: '3715 Hillcrest Drive',
      location: 'Seattle',
    },
    {
      lat: 15.9267,
      long: 47.9218,
      name: 'Hope A. Atkins',
      street: '3715 Hillcrest Drive',
      location: 'Seattle',
    },
    {
      lat: 23.4425,
      long: 58.4438,
      name: 'Samuel R. Bailey',
      street: '2883 Raoul Wallenberg Place',
      location: 'Cheshire',
    },
    {
      lat: 23.5425,
      long: 58.3438,
      name: 'Samuel R. Bailey',
      street: '2883 Raoul Wallenberg Place',
      location: 'Cheshire',
    },
    {
      lat: -37.8927369333,
      long: 175.4087452333,
      name: 'Samuel R. Bailey',
      street: '3228 Glory Road',
      location: 'Nashville',
    },
    {
      lat: -38.9064188833,
      long: 175.4441556833,
      name: 'Samuel R. Bailey',
      street: '3228 Glory Road',
      location: 'Nashville',
    },
    {
      lat: -12.409874,
      long: -65.596832,
      name: 'Ann J. Perdue',
      street: '921 Ella Street',
      location: 'Dublin',
    },
    {
      lat: -22.090887,
      long: -57.411827,
      name: 'Jorge C. Woods',
      street: '4800 North Bend River Road',
      location: 'Allen',
    },
    {
      lat: -19.019585,
      long: -65.261963,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: -16.500093,
      long: -68.214684,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: -17.413977,
      long: -66.165321,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: -16.489689,
      long: -68.119293,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: 54.766323,
      long: 3.08603729,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: 54.866323,
      long: 3.08603729,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: 49.537685,
      long: 3.08603729,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: 54.715424,
      long: 0.509207,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: 44.891666,
      long: 10.136665,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: 48.078335,
      long: 14.535004,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: -26.358055,
      long: 27.398056,
      name: 'Russ E. Panek',
      street: '4068 Hartland Avenue',
      location: 'Appleton',
    },
    {
      lat: -29.1,
      long: 26.2167,
      name: 'Wilbur J. Dry',
      street: '2043 Jadewood Drive',
      location: 'Northbrook',
    },
    {
      lat: -29.883333,
      long: 31.049999,
      name: 'Wilbur J. Dry',
      street: '2043 Jadewood Drive',
      location: 'Northbrook',
    },
    {
      lat: -26.266111,
      long: 27.865833,
      name: 'Wilbur J. Dry',
      street: '2043 Jadewood Drive',
      location: 'Northbrook',
    },
    {
      lat: -29.087217,
      long: 26.154898,
      name: 'Wilbur J. Dry',
      street: '2043 Jadewood Drive',
      location: 'Northbrook',
    },
    {
      lat: -33.958252,
      long: 25.619022,
      name: 'Wilbur J. Dry',
      street: '2043 Jadewood Drive',
      location: 'Northbrook',
    },
    {
      lat: -33.977074,
      long: 22.457581,
      name: 'Wilbur J. Dry',
      street: '2043 Jadewood Drive',
      location: 'Northbrook',
    },
    {
      lat: -26.563404,
      long: 27.844164,
      name: 'Wilbur J. Dry',
      street: '2043 Jadewood Drive',
      location: 'Northbrook',
    },
    {
      lat: 51.21389,
      long: -102.462776,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 52.321945,
      long: -106.584167,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 50.288055,
      long: -107.793892,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 52.7575,
      long: -108.28611,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 50.393333,
      long: -105.551941,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 50.930557,
      long: -102.807777,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 52.856388,
      long: -104.610001,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 52.289722,
      long: -106.666664,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 52.201942,
      long: -105.123055,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 53.278046,
      long: -110.00547,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 49.13673,
      long: -102.990959,
      name: 'Joseph B. Poole',
      street: '3364 Lunetta Street',
      location: 'Wichita Falls',
    },
    {
      lat: 45.484531,
      long: -73.597023,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 45.266666,
      long: -71.900002,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 45.349998,
      long: -72.51667,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 47.333332,
      long: -79.433334,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 45.400002,
      long: -74.033333,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 45.683334,
      long: -73.433334,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 48.099998,
      long: -77.783333,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 45.5,
      long: -72.316666,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 46.349998,
      long: -72.550003,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 48.119999,
      long: -69.18,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 45.599998,
      long: -75.25,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 46.099998,
      long: -71.300003,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 45.700001,
      long: -73.633331,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 47.68,
      long: -68.879997,
      name: 'Claudette D. Nowakowski',
      street: '3742 Farland Avenue',
      location: 'San Antonio',
    },
    {
      lat: 46.716667,
      long: -79.099998,
      name: '299',
    },
    {
      lat: 45.016666,
      long: -72.099998,
      name: '299',
    },
  ];

  const storage = {
    isDark: false,
  };

  const { L } = window;
  const mapContainer = document.getElementById('map');
  if (L && mapContainer) {
    let filterColor = [
      'bright:101%',
      'contrast:101%',
      'hue:23deg',
      'saturate:225%',
    ];
    let tileLayerTheme =
      'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png';
    let tiles;
    if (storage.isDark) {
      filterColor = [
        'invert:98%',
        'grayscale:69%',
        'bright:89%',
        'contrast:111%',
        'hue:205deg',
        'saturate:1000%',
      ];
    }

    if (window.is.ie()) {
      if (storage.isDark) {
        tileLayerTheme =
          'https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png';
      }
      tiles = L.tileLayer(tileLayerTheme, {
        attribution: null,
        transparent: true,
      });
    } else {
      tiles = L.tileLayer.colorFilter(tileLayerTheme, {
        attribution: null,
        transparent: true,
        filter: filterColor,
      });
    }

    const map = L.map('map', {
      center: L.latLng(10.737, 0),
      zoom: 0,
      layers: [tiles],
      minZoom: 1.3,
      zoomSnap: 0.5,
      dragging: !L.Browser.mobile,
      tap: !L.Browser.mobile,
    });

    const mcg = L.markerClusterGroup({
      chunkedLoading: false,
      spiderfyOnMaxZoom: false,
    });

    points.map((point) => {
      const { name, location, street } = point;
      const icon = L.icon({
        iconUrl: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAACXBIWXMAAAFgAAABYAEg2RPaAAADpElEQVRYCZ1XS1LbQBBtybIdiMEJKSpUqihgEW/xDdARyAnirOIl3MBH8NK7mBvkBpFv4Gy9IRSpFIQiRPyNfqkeZkY9HwmFt7Lm06+7p/vN2MmyDIrQ6QebALAHAD4AbFuWfQeAAACGs5H/w5jlsJJw4wMA+GhMFuMA99jIDJJOP+ihZwDQFmNuowWO1wS3viDXpdEdZPEc0odruj0EgN5s5H8tJOEEX8R3rbkMtcU34NTqhe5nSQTJ7Tkk80s6/Gk28scGiULguFBffgdufdEwWoQ0uoXo8hdAlooVH0REjISfwZSlyHGh0V5n6aHAtKTxXI5g6nQnMH0P4bEgwtR18Yw8Pj8QZ4ARUAI0Hl+fQZZGisGEBVwHr7XKzox57DXZ/ij8Cdwe2u057z9/wygOxRl4S2vSUHx1oucaMQGAHTrgtdag9mK5aN+Wx/uAAQ9Zenp/SRce4TpaNbQK4+sTcGqeTB/aIXv3XN5oj2VKqii++U0JunpZ8urxee4hvjqVc2hHpBDXuKKT9XMgVYJ1/1fPGSeaikzgmWWkMIi9bVf8UhotXxzORn5gWFchI8QyttlzjS0qpsaIGY2MMsujV/AUSdcY0dDpB6/EiOPYzclR1CI5mOez3ekHvrFLxa7cR5pTscfrXjk0Vhm5V2PqLUWnH3R5GbPGpMVD7E1ckXesKBQ7AS/vmQ1c0+kHuxpBj98lTCm8pbc5QRJRdZ6qHb/wGryXq3Lxszv+5gySuwvxueXySwYvHEjuQ9ofTGKYlrmK1EsCHMd5SoD7mZ1HHFCBHLNbMEshvrugqWLn01hpVVJhFgVGkDvK7hR6n2B+d9C7xsqWsbkqHv4cCsWezEb+o2SR+SFweUBxfA5wH7kShjKt2vWL57Px3GhIFEezkb8pxvUWHYhotAfCk2AtkEcxoOttrxUWDR5svb1emSQKj0WXK1HYIgFREbiBqmoZcB2RkbE+byMZiosorVgAZF1ID7yQhEs38wa7nUqNDezdlavC2HbBGSQkGgZ8uJVBmzeiKCRRpEa9ilWghORVeGB7BxeSKF5xqbFBkxBrFKUk/JHA7ppENQaCnCjthK+3opCEYyANztXmZN858cDYWSUSHk3A311GAZDvo6deNKUk1EsqnJoQlkYBNlmxQZeaMgmxoUokICoHDce351RCCiuKoirJWEgNOYvQplM2VCLhUqF7jf94rW9kHVUjQeheV4riv0i4ZOzzz/2y/+0KAOAfr4EE4HpCFhwAAAAASUVORK5CYII=
        `,
      });
      const marker = L.marker(
        new L.LatLng(point.lat, point.long),
        { icon },
        {
          name: name,
          location: location,
        }
      );
      const popupContent = `
        <h6 class="mb-1">${name}</h6>
        <p class="m-0 text-500">${street}, ${location}</p>
      `;
      const popup = L.popup({ minWidth: 180 }).setContent(popupContent);
      marker.bindPopup(popup);
      mcg.addLayer(marker);
      return true;
    });
    map.addLayer(mcg);
  }
};

export default leafletActiveUserInit;
